@charset "utf-8";
.blind{position:absolute;overflow:hidden;clip:rect(0 0 0 0);margin:-1px;width:1px;height:1px}

.gnb-chk{display:flex;justify-content:space-between;align-items:center;position:fixed;left:0;right:0;z-index:9998;margin-top:0;padding: 4em;box-sizing:border-box;transition:all .5s;-webkit-transition:all .5s;-ms-transition:all .5s}
.gnb-chk.on{background:#000;padding:1.5em 4em;transition:all .5s;-webkit-transition:all .5s;-ms-transition:all .5s}


.top-logo{display:inline-block;transition:all .2s;-webkit-transition:all .2s;-ms-transition:all .2s;}

header{z-index:9000}
header .btn-menu{float:right;margin:0 0 0 2vw}

.btn-menu{width:30px;height:24px;position:relative;-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);-webkit-transition: .2s ease-in-out;-moz-transition: .2s ease-in-out;-o-transition: .2s ease-in-out;transition: .2s ease-in-out;cursor: pointer}
.btn-menu:hover,.btn-menu:focus,.btn-menu:active{outline:none;border:none}
.btn-menu span{display:block;position:absolute;height:3px;width:100%;opacity:1;left:0;-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);-webkit-transition: .25s ease-in-out;-moz-transition: .25s ease-in-out;-o-transition: .25s ease-in-out;transition: .25s ease-in-out}
.btn-menu span:nth-child(1){top:0px}
.btn-menu span:nth-child(2){top:9px;left:8px;width:22px}
.btn-menu span:nth-child(3){top:18px}
.btn-menu:not(.open):hover span:nth-child(1),
.btn-menu:not(.open):hover span:nth-child(3){left:8px;width:22px}
.btn-menu:not(.open):hover span:nth-child(2){left:0;width:100%}
.btn-menu.open span:nth-child(1){top:9px;-webkit-transform: rotate(135deg);-moz-transform: rotate(135deg);-o-transform: rotate(135deg);transform: rotate(135deg)}
.btn-menu.open span:nth-child(2){opacity:0;left: -60px}
.btn-menu.open span:nth-child(3){top: 9px;-webkit-transform: rotate(-135deg);-moz-transform: rotate(-135deg);-o-transform: rotate(-135deg);transform: rotate(-135deg)}

.btn-menu span{background:#fff}
.top-logo a{display:block;width:10rem;height:2.563rem;background:url('../images/icon/logo-avivplay.png')0 0/10rem no-repeat;transition:background .3s;-webkit-transition:background .3s;-ms-transition:background .3s}


[data-aos^=fade].aos-animate.aosDelay1{transition-delay:.2s}
[data-aos^=fade].aos-animate.aosDelay2{transition-delay:.4s}
[data-aos^=fade].aos-animate.aosDelay3{transition-delay:.6s}
[data-aos^=fade].aos-animate.aosDelay4{transition-delay:.8s}
[data-aos^=fade].aos-animate.aosDelay5{transition-delay:1s}
[data-aos^=fade].aos-animate.aosDelay6{transition-delay:1.2s}
[data-aos^=fade].aos-animate.aosDelay7{transition-delay:1.4s}

#gnb{display:none;position:fixed;top:0;right:0;left:0;bottom:0;z-index:9997;height:100vh;background:#000}
#gnb .menu-con{position:absolute;top:50%;left:50%;width:100%;text-align:center;transform:translate3d(-50%, -50%, 0);-webkit-transform:translate3d(-50%, -50%, 0);-ms-transform:translate3d(-50%, -50%, 0)}
#gnb ul li{line-height:1.8}
#gnb ul a{animation-name:m-eft;animation-duration:1s;position:relative;display:inline-block;color:#fff;font-size:3.50em;font-weight:100;letter-spacing:.05em;animation-fill-mode:both;text-transform: capitalize;}
#gnb ul a:hover{transition:all .2s;font-weight:900}

#gnb.show ul li a{opacity:0;}
#gnb.show ul li:nth-child(2) a{animation-delay:.2s}
#gnb.show ul li:nth-child(3) a{animation-delay:.4s}
#gnb.show ul li:nth-child(4) a{animation-delay:.6s}
#gnb.show ul li:nth-child(5) a{animation-delay:.8s}

#gnb.hide ul li a{animation-name:m-eftClose;animation-duration:.5s}
#gnb.hide ul li:nth-child(2) a{animation-delay:.1s}
#gnb.hide ul li:nth-child(3) a{animation-delay:.2s}
#gnb.hide ul li:nth-child(4) a{animation-delay:.3s}
#gnb.hide ul li:nth-child(5) a{animation-delay:.4s}

.menu-con .contact-con{display:inline-block;width:100%;max-width:35em;margin-top:5em}
.menu-con .contact-con .contact-group{width:100%;color:#fff}

.contact-con:first-child{width:60%}
.contact-con .con-itemWrap{width:50%;text-align:center}
.contact-con .con-itemWrap span{display:block;margin-top:.3em}
.contact-con .con-itemWrap .con-item{display:inline-block;padding-left:1em;text-align:left;vertical-align:super}
.contact-con .con-itemWrap span:first-child{margin-top:0;font-size:1.250em;font-weight:500}
.contact-con p{margin-top:2em}
.contact-con p:first-child{margin-top:0}
.contact-con a{display:inline-block;min-width:10em;padding:1.1em 1em;font-weight:500;transition-property:border-color, color;transition-duration:.2s;-webkit-transition-duration:.2s;-ms-transition-duration:.2s}
.contact-con a:hover{border-color:#6ee9ff;color:#6ee9ff !important;transition-property:border-color, color;transition-duration:.2s;-webkit-transition-duration:.2s;-ms-transition-duration:.2s}
.contact-con .contact-group{display:table;width:100%;margin: 0 auto;max-width:35em}
.contact-con .contact-group .con-itemWrap{position:relative;display:table-cell;width:50%;box-sizing:border-box;vertical-align:middle}
.contact-con .contact-group .con-itemWrap:first-child{border-right:1px solid rgba(255,255,255,.5)}
.ico-contact{display:inline-block;width:3.8em;height:3.8em;background-image:url('../images/icon/ico-common.png');background-repeat:no-repeat;background-size:8.3em;background-position-y:-1.55em}
.ico-contact.tel{background-position-x:0}
.ico-contact.mail{background-position-x:-4.4em}


@keyframes m-eft{0%{opacity:0;top:-1em}100%{opacity:1;top:0}}
@keyframes m-eftClose{0%{opacity:1;top:0}100%{opacity:0;top:-1em}}



.fL {float: left;color: #999;font-size: 14px;}


.fR {float: right;color: #999;font-size: 14px;}
.fR .txt-link {color: #fff;font-weight: 500;vertical-align: middle;}

/* #footer{height:50rem;}/ */
#footer{background:#000;color:#fff}
.footer-inner {overflow: hidden;max-width: 1640px;margin: 0 auto;padding: 3.853rem 1em 3.853rem;box-sizing: border-box;}
.footer-inner .fL .company-info {display: none;}
.footer-inner .company-info {overflow: hidden;display: inline-block;vertical-align: middle;}
.footer-inner .company-info li {position: relative;float: left;padding: 0 1.3rem;}
.footer-inner .company-info li:first-child {padding-left: 0;}
/* .footer-inner .company-info li:first-child:before {display: none;} */
.footer-inner .company-info li:nth-child(2):before{content: "";position: absolute;top: 50%;left: 0;display: block;width: 1px;height: 12px;background: #999;transform: translateY(-50%);}
.footer-inner .company-info li:nth-child(2):after {content: "";position: absolute;top: 50%;right: 0;display: block;width: 1px;height: 12px;background: #999;transform: translateY(-50%);}
.footer-inner .fR .txt-link{margin-left:1.3rem}