@import url("lib/lds-ring.css");

.sec6 .partner-inner > div{max-width:1536px;width:100%;margin:0 auto;}
.sec6 .partner-inner > div:not(:first-child){margin-top:2rem}

.sec6 .works-con > ul > li{
  transition: all .3s;
  position:relative;
}

.sec6 .works-con > ul > li.fadeInLeft{

  
}

.sec6 .works-con > ul > li > div:not(.lds-ring){
    transform: translateX(-50px);
    opacity: 0;
    transition: opacity 0.7s, transform 0.3s;
}

.sec6 .works-con > ul > li.fadeInLeft > div{
    opacity: 1;
    transform: translateX(0px);
}
.sec6 .works-con li.d0 > div{
transition-delay: 0s;
}
.sec6 .works-con li.d1 > div {
transition-delay: 0.1s;
}
.sec6 .works-con li.d2 > div {
transition-delay: 0.2s;
}
.sec6 .works-con li.d3 > div {
transition-delay: 0.3s;
}
.sec6 .works-con li.d4 > div {
transition-delay: 0.4s;
}
.sec6 .works-con li.d5 > div {
transition-delay: 0.5s;
}
.sec6 .works-con li.d6 > div {
transition-delay: 0.6s;
}
.sec6 .works-con li.d7 > div {
transition-delay: 0.7s;
}
.sec6 .works-con li.d8 > div {
transition-delay: 0.8s;
}
.sec6 .works-con li.d9 > div {
transition-delay: 0.9s;
}

.list-videoCon-wrap .list-videoCon-item.on {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
}
.sec6 .partner-inner > div{max-width:1536px;width:100%;margin:0 auto;}
.sec6 .partner-inner > div:not(:first-child){margin-top:2rem}


.ico-list {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: url("../../images/icon/ico-list.png") no-repeat 50% 50%;
}

.contact-btn.active{
  width: 20rem;
  height: 20rem;
  line-height: 20rem;
  opacity:0;
}
.sec6 .works-con .list-l img{width:470px;}
.sec6 .works-con .list-r .r-bottom li{}
.sec6 .works-con .list-r .r-bottom li img{width:210px;}



.sec6 .works-con > ul > li .lds-ring {
  display:block;
  position:absolute;
  left:50%;
  margin-left:-32px;
  top:50%;
  margin-top:-32px;
}

.sec6 .works-con > ul > li.fadeInLeft .lds-ring{
  display:none;
}

