@import url("initialize.css");
body,html {overflow-x:hidden;}
section, div {box-sizing: border-box;}
.txt-bold{font-weight:700}
video{min-width:100%}
/* main */

.gnb-chk.main {position:relative;display:block;padding:0}
.gnb-chk.main .top-logo{position:absolute;display:block;top:4rem;left:4rem;}
.gnb-chk.main .btn-menu{position:absolute;display:block;top:4rem;right:4rem;}
.gnb-chk.main.color-white{padding:0}

.loading {opacity: 0; position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 9999;display: flex;justify-content: center;align-items: center;background:#000;transition: opacity 1s;}
.loading .st0 {fill: #497EC1;}
.loading .st1 {fill: #fff;}
.on .loading {opacity:1;}

.swiper-container {width: 100%;height: 100vh;}
.swiper-slide{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;height:100vh;}

.sec1{background:#000}
.section1-left{background:#000;color:#fff;}

.tit {color: #fff;font-family: "Myriad Pro";text-transform: uppercase;font-size: 6.25em;font-weight: 500;line-height: 1;margin-left: 0.2em}
.tit:first-child {margin-left: 0;}

.tit-sub {position: relative;display: block;color: #222;font-family: "manrope";font-weight: 700;font-size: 2.813em;text-align: center;text-transform: uppercase;line-height: 1.05;transition: all 0.2s;}
.tit-sub.white {color: #fff;z-index: 2;transition: all 0.2s;}

.intro-tit {transition: all 0.2s;}
.intro-tit .showEft {top: 5.5em;opacity: 0;transition: all ease-in-out 1.2s;}
.intro-tit .showEft.on {top: 0;opacity: 1;transition: all ease-in-out 1.2s;}

.intro-tit .tit-line {position: relative;display: flex;align-items: center;width:1380px}
.intro-tit .tit-line:first-child {align-items: center;}
.intro-tit .tit-line-outer {position: relative;height: 6.25em;overflow: hidden;  display: flex;flex-direction: column;margin-left: 9.5em;}
.intro-tit .tit-line-outer .tit-line {position: absolute;top: 5.5em;opacity: 0;}
.intro-tit .tit-line-outer .tit-line .img-txt-concept {overflow: hidden;width: 15.75em;height: 5em;margin-left: 1.5em;background-image: url("../images/photo/img-txtConcept.png");}
.intro-tit .tit-line-outer .tit-line:nth-child(1) .img-txt-concept {background-position: 0 0;}
.intro-tit .tit-line-outer .tit-line:nth-child(2) .img-txt-concept {background-position-y: -5em;}
.intro-tit .tit-line-outer .tit-line:nth-child(3) .img-txt-concept {background-position-y: -10em;}
.intro-tit .tit-line-outer .tit-line:nth-child(4) .img-txt-concept {background-position-y: -15em;}
.intro-tit .tit-line-outer .tit-line.show {display: flex;animation: txtShow 2.3s ease;}

.gra-con-wrap {overflow: hidden;position: relative;width: 15.75em;height: 5em;margin-left: 1.3em;}

.gra-con {position: absolute;width: 25.188em;height: 25.188em;top: 50%;left: 50%;transform: translate(-50%, -50%);
  background: #258df7;
  background: linear-gradient(90deg, #258df7 0%, #df94e3 100%);
  animation: graAni 1.5s linear infinite;
  -webkit-animation: graAni 1.5s linear infinite;
  -moz-animation: graAni 1.5s linear infinite;
  -o-animation: graAni 1.5s linear infinite;
  animation: graAni 1.5s linear infinite;
}

.play-area-wrapper{width:100%;height:100%;position:relative;overflow:hidden;}
.background-video{width:100%;}
.background-video video{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.play-area-wrapper .btn-sound.on{background:url(../images/icon/sound-off.png)50%/32px no-repeat;}
.play-area-wrapper .btn-sound{position:absolute;left:3rem;bottom:2rem;width:2.5rem;height:2.5rem;background:url(../images/icon/sound-on.png)50%/32px no-repeat;text-indent:-999rem}

.scrl-con{display:inline-block;position:absolute;top:50%;right:2.5rem;transform:rotate(-90deg);text-align:center;}
.scrl-con .scrl-inner{display:block;font-family:'Manrope';font-size:.875rem;font-weight:600;letter-spacing:.25rem;}
.scrl-con .ico-scrl{display:inline-block;width:1.5rem;height:1.2rem;background:url(../images/icon/main-scroll.png)50%/1.2rem no-repeat;animation:scroll 1s linear infinite;}

.fp-viewing-2 .top-logo a {background-position-y:0;}
.fp-viewing-2 .btn-menu span{background:#fff}

.fp-viewing-5 .top-logo a, .fp-viewing-6 .top-logo a{background-position-y:100%;}
.fp-viewing-5 .btn-menu span, .fp-viewing-6 .btn-menu span{background:#000}

.fp-viewing-5 .contact-btn{background:#000;color:#fff}

.iScrollVerticalScrollbar{display:none!important;}

.aviv-area{color:#fff}
.aviv-area .swiper-wrapper{height:100vh}
.aviv-area .swiper-wrapper .swiper-slide{height:100vh;}
.aviv-area .swiper-wrapper .swiper-slide.bg1{background:url(../images/photo/sec2-bg1.png)50%/cover no-repeat}
.aviv-area .swiper-wrapper .swiper-slide.bg2{background:url(../images/photo/sec2-bg2.png)50%/cover no-repeat}

.aviv-area .tit-sub{font-family:'Manrope';font-size:3.125rem;;color:#fff;}
.aviv-area .sec2-inner{text-align:center;}
.aviv-area .sec2-con{display:flex;min-height:34rem;padding:5rem 0;align-items:center;}
.aviv-area .sec2-con .circle-wrap{display:inline-block;}
.aviv-area .circle-line{display:flex;justify-content:center;align-items:center;width:23rem;height:23rem;margin:0 -1rem;border-radius:50rem;border:1px solid #fff;}
.aviv-area .circle-line p:nth-child(1){font-family:'Manrope';font-size:2.5rem;font-weight:700;line-height:1}
.aviv-area .circle-line p:nth-child(2){margin-top:.75rem;font-size:1.125rem;}
.aviv-area .sub-txt{font-family:'Myriad Pro';font-size:1.875rem;font-weight:300}
.aviv-area .sub-txt{font-family:'Myriad Pro';font-size:1.875rem;font-weight:300}


.aviv-area .process{display:flex;align-items:center;}
.aviv-area .process .circle-line{align-items:flex-start;width:17.25rem;height:17.25rem;}
.aviv-area .process .circle-line > div{padding-top:4rem;}
.aviv-area .process .num{font-family:'Myriad Pro';font-size:2rem;font-weight:700}
.aviv-area .process .middle{font-size:1.25rem;font-weight:400}
.aviv-area .process .small{padding-top:1rem;font-size:.875rem;font-weight:300;opacity:.5}
.aviv-area .process .around{display:flex;justify-content:center;align-items:center;width:12.5rem;height:12.5rem;margin:0 -1rem;padding:1rem;border-radius:50rem;background:rgba(255,255,255,.2);box-sizing:border-box;}
.aviv-area .process .around > div{display:flex;justify-content:center;align-items:center;flex-direction:column;width:8.5rem;height:8.5rem;border-radius:50rem;background:rgba(255,255,255,.4)}
.aviv-area .process .around .small{padding-top:.5rem;opacity:1;font-size:1rem;font-weight:500}
.aviv-area .process .around.blue{background:rgba(47,68,226,.3)}
.aviv-area .process .around.blue >div{background:rgba(47,68,226,.7)}

.work-area .swiper-slide{position:relative;text-align:center;}
.work-area .bg-black{position:absolute;top:0;left:0;right:0;bottom:0;display:flex;justify-content:center;align-items:center;width:100%;height:100%;background:rgba(0,0,0,.8);transition: all .8s;}
.work-area .bg-black p{color:#fff;font-size:1.5rem;font-weight:400;font-size:1.5rem;}
.work-area .bg-black .title{font-family:'Manrope';font-weight:700;font-size:6.25rem;}
.work-area .bg-video{position:relative;top:0;right:0;bottom:0;left:0;width:100%}
.work-area .bg-video video{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.sec6 .scroll{padding:10rem 0 0}
.sec6 .sec6-inner{max-width:1180px;margin:0 auto;}
.sec6 .tit-sub{font-size:3.125rem;}
.sec6 .works-tab{overflow-x:auto;padding:5rem 0 2rem;white-space:nowrap}
.sec6 .works-tab ul{display:flex;justify-content:space-between;}
.sec6 .works-tab ul li{padding:0 0 0 1rem;}
.sec6 .works-tab .tab-btn{display:block;padding:.5rem 1.5rem;border-radius:15rem;border:1px solid #000;font-size:1rem;font-weight:400;color:#000;transition:all .3s;}
.sec6 .works-tab .tab-btn.on{background:#000;color:#fff!important}
.sec6 .works-tab .tab-btn:hover{background:#000;color:#fff!important}

.sec6 .works-con{}
.sec6 .works-con .round-img{overflow:hidden;border-radius:1rem;}
.sec6 .works-con li{display:flex;justify-content:flex-start;padding:1.5rem 0}
.sec6 .works-con .list-l{padding-right:1rem;}
.sec6 .works-con .list-r{display:flex;justify-content:space-between;flex-direction:column;}
.sec6 .works-con .list-r .r-top{padding:1.5rem 0 1.5rem .5rem}
.sec6 .works-con .list-r .r-top .work-tit a{font-size:1.5rem;font-weight:700;color:#000}
.sec6 .works-con .list-r .r-top .work-tit a:hover{color:#2138e3}
.sec6 .works-con .list-r .r-top .work-txt{padding-top:.5rem;font-size:1.125rem;font-weight:500;}
.sec6 .works-con .list-r .r-bottom{display:flex;}
.sec6 .works-con .list-r .r-bottom li{padding:0 .5rem;}

.sec6 .btn-wrap{padding-top:2rem;text-align:center;}
.sec6 .more-btn{display:inline-block;padding:.5rem 1.5rem;border-radius:15rem;border:1px solid #000;font-size:1rem;font-weight:400;color:#000;}

.sec6 .partner{margin-top:9rem;padding:9rem 0;background:#fafafa}
.sec6 .partner-inner{padding-top:5rem}
.sec6 .partner-inner li img{max-width:8rem;max-height:4rem;margin:0 auto}
.sec6 .partner-inner ul:not(:first-child){margin-top:2rem}
.contact-btn{position:fixed;right:2rem;bottom:2rem;width:7rem;height:7rem;border-radius:50rem;background:#fff;color:#000;text-transform:uppercase;;font-family:'manrope';font-size:1.125rem;font-weight:700;text-align:center;line-height:7rem;transition:.5s}
.work-area .swiper-wrapper  .swiper-slide.swiper-slide-active {height:100%;}
.work-area .swiper-wrapper .swiper-slide.swiper-slide-active {z-index:1}
.work-area .swiper-wrapper .swiper-slide.swiper-slide-active + .swiper-slide.swiper-slide-next {opacity:.8!important;}
.work-area .swiper-wrapper .swiper-slide{transition:all .5s;}


.rollingbanner{width: 100%;height: 20rem;}
.rollingbanner > .wrap{position: relative;width: 100%;height: 20rem;margin: 0 auto;box-sizing: border-box;overflow: hidden;}

.roller{position: absolute;height: 100%;}
.roller > ul{margin: 0;list-style: none;padding: 9px 0;display: flex;flex-flow: row nowrap;align-items: center;}
.roller > ul > li{width:12rem;padding-right:2rem;}
.roller > ul > li img{width:10rem}

.bottom-area{overflow:hidden;position:relative;}
.bottom-area .bg-inner{position:relative;min-height:18rem;background:url(../images/photo/bg-main-bottom.png)50%/cover no-repeat;text-align:center;transition:1s;z-index:-1}
.bottom-area:hover .bg-inner{transform:scale(1.05);}
.bottom-area .btn-inner{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.bottom-area a{position:relative;display:inline-block;font-family:'manrope';font-size:4.5rem;font-weight:700;color:#fff;text-align:center;line-height:1.2;transition:.3s;}
.bottom-area a:after {position: absolute; content:""; display: block; border-bottom: 3px solid #fff; transition: width 150ms ease-in-out; left: auto; width: 0;}
.bottom-area a:hover:after {width: 100%;}


/* about */
.sub-container{}
.con-width{max-width:1200px;margin:0 auto;}

.sub-txt .pc-block{display:block;}

.sub-top{display:flex;justify-content:center;width:100%;height:31.25em;background:#000;}
.sub-top .sub-top-inner{display:flex;align-items:center;width:100%;max-width:1200px;height:100%;}
.sub-top h2{font-size:2.875rem;font-weight:700;color:#fff;}

.sub-top.about{background:url(../images/photo/bg-top-about.png)70% 50%/cover no-repeat;}
.sub-top.studio{background:url(../images/photo/bg-top-studio.png)70% 50%/cover no-repeat;}

.sub-con{padding-top:9rem;text-align:center;}
.sub-con h3{font-size:2.15rem;font-weight:700;line-height:1.3;letter-spacing:-.7px;}
.sub-con .sub-txt{padding-top:1.25rem;font-size:1.125rem;font-weight:400;}

.about-con .round-box{overflow:hidden;margin-top:3rem;border-radius:1.25rem;background:#000;}
.about-con .round-box img{width:100%}

.about-con:nth-child(2){padding-top:7rem}
.about-box-list{display:flex;flex-wrap:wrap;margin-top:2.5rem}
.about-box-list .box{width:50%;padding:.5rem;box-sizing:border-box;text-align:left;}
.about-box-list .box .box-inner{overflow:hidden;padding:2rem 1.5rem;border-radius:1.25rem;background:#f4f4f4}
.about-box-list .about-icon{display:inline-block;width:2.75rem;height:2.75rem;margin-bottom:.5rem;}
.about-box-list .about-icon.video{background:url(../images/icon/about-con1.png)50%/2.75rem no-repeat}
.about-box-list .about-icon.virtual{background:url(../images/icon/about-con2.png)50%/2.75rem no-repeat}
.about-box-list .about-icon.live{background:url(../images/icon/about-con3.png)50%/2.75rem no-repeat}
.about-box-list .about-icon.studio{background:url(../images/icon/about-con4.png)50%/2.75rem no-repeat}
.about-box-list .about-icon.sound{background:url(../images/icon/about-con5.png)50%/2.75rem no-repeat}
.about-box-list .box-tit{font-size:1.25rem;font-weight:700;}
.about-box-list .box-txt{font-size:1rem;font-weight:500;}
.about-box-list .box-bt{margin-top:1rem;padding-top:1rem;border-top:1px solid #ddd;}
.about-box-list .box-bt p{max-width:29.5rem;font-size:1rem;font-weight:400;}

.about-bt{overflow:hidden;width:100%;margin-top:10rem;padding:8rem 0 8rem;background:url(../images/photo/bg-about-bottom.png)}
.about-bt .about-bt-tit{padding-bottom:3.5rem;font-size:2.15rem;font-weight:700;line-height:1.3;letter-spacing:-.7px;color:#fff;}
.about-bt .bt-con{display:flex;justify-content:center;}
.about-bt .bt-con .bt-con-inner{position:relative;display:flex;flex-direction:column;align-items:center;margin:0 1rem;}
.about-bt .bt-con .bt-con-inner:after{display:block;content:"";position:absolute;top:7.5rem;right:-2rem;width:2rem;height:1px;background-color:rgba(255,255,255,.2);}
.about-bt .bt-con .bt-con-inner:nth-child(1):before{display:block;content:"";position:absolute;top:7.5rem;right:15rem;width:50rem;height:1px;background-color:rgba(255,255,255,.2);}
.about-bt .bt-con .bt-con-inner:nth-child(3):after{left:15rem;width:50rem;}
.about-bt .cir-txt{position:relative;display:flex;justify-content:center;align-items:center;width:15rem;height:15rem;border-radius:50rem;;background:rgba(255,255,255,.1);}
.about-bt .cir-txt:before{display:block;content:"";position:absolute;top:50%;left:50%;width:12rem;height:12rem;border-radius:50rem;background:rgba(255,255,255,.1);transform:translate(-50%, -50%);}
.about-bt .cir-txt span{color:#fff;font-size:1.25rem;font-weight:700}

.about-bt .bt-txt{padding-top:1.5rem;text-align:left;}
.about-bt .bt-txt p{position:relative;padding-left:.75rem;font-size:1rem;font-weight:500;line-height:1.6;color:#999}
.about-bt .bt-txt p:after{display:block;content:"";position:absolute;top:.75rem;left:0;width:3px;height:3px;border-radius:1rem;background-color:#999;}

/* studio */
.studio-con{padding:5rem 0;}
.studio-con:first-child{padding:0 0 5rem;}
.studio-con:last-child{padding:5rem 0 8rem;}
.studio-con:nth-child(even){background:#f7f7f7}

.studio-slide{padding-top:3rem;}
.studio-slide .swiper-slide{height:auto;padding:0 1rem;box-sizing:border-box;}
.studio-slide .swiper-slide .round-box{overflow:hidden;border-radius:1.5rem;}
.studio-slide .swiper-slide img{width:100%;}

.studio-img-area{padding-top:3rem;}
.studio-img-area .round-box{overflow:hidden;border-radius:1.5rem;}
.studio-img-area .round-box img{width:100%}
.studio-img-area .round-box.wide{margin-bottom:2rem;}
.studio-img-area .round-box.hover-evt{position:relative;width:100%;}
.studio-img-area .round-box.hover-evt .hover-info{display:flex;justify-content:center;align-items:center;position:absolute;top:50%;left:50%;width:7rem;height:7rem;transform:translate(-50%, -50%);}
.studio-img-area .round-box.hover-evt .hover-info p{color:#fff;font-size:.975rem;font-weight:500;line-height:1.3}
.studio-img-area .round-box.hover-evt .hover-info:before{display:block;content:"";position:absolute;width:9rem;height:9rem;border-radius:50rem;background:#2138e3;transform: translate(-50%, -50%);opacity:0.3;z-index:-1;animation: wave-animate 1.5s infinite ease-out;}
.studio-img-area .round-box.hover-evt .hover-info:after{display:block;content:"";position:absolute;width:7rem;height:7rem;border-radius:50rem;background:#2138e3;transform: translate(-50%, -50%);opacity:1;z-index:-1;animation: wave-animate2 1.5s infinite ease-out;}
/* .studio-img-area .round-box.hover-evt .img-wrap{} */
.studio-img-area .round-box.hover-evt .hidden{opacity:0;position:absolute;top:0;right:0;bottom:0;left:0;z-index:2}
.studio-img-area .hover-evt:hover .hidden{opacity:1;transition:.5s;}

.studio-img-area .img-list{display:flex;flex-wrap:wrap;justify-content:space-between;text-align:left;}
.studio-img-area .img-list li{width:49%;}
.studio-img-area .img-list .img-bt-txt{padding:1rem 0 2rem;}
.studio-img-area .img-list .img-tit{padding-bottom:.25rem;font-size:1.125rem;font-weight:700;}
.studio-img-area .img-list .img-txt{font-size:1rem;font-weight:400;word-break:break-all;}

.studio-img-area .img-list.col4{justify-content:start;}
.studio-img-area .img-list.col4 li{width:25%;padding:.75rem;text-align:center;box-sizing:border-box;}
.studio-img-area .img-list.col4 li > div{overflow:hidden;border-radius:1.5rem;padding:2.5rem 0 3rem;background:#fafafa;}
.studio-img-area .img-list.col4 .round-box img{width:8rem;}
.studio-img-area .img-list.col4 .img-bt-txt{padding:1rem 0 0;line-height:1;}

/* contact */
.sub-container.contact{background:#121212;padding:15rem 0 9rem}
.contact-wrap{max-width: 680px;width: 100%;margin: 0 auto;background: #121212;box-sizing: border-box;transition: all 0.2s;}

.contact-wrap .form-top{display: table;width: 100%;}
.contact-wrap .form-top .form-top-tit{display: table-cell;}
.contact-wrap .form-top .form-top-tit h1{font-family: "manrope";font-size: 2.813em;color: #fff;font-weight: 600;line-height: 1;}
.contact-wrap .form-top .form-top-tit p{margin-top: 0.9em;font-size: 1em;font-weight: 400;color: #fff;line-height: 1;}
.contact-wrap .form-top .form-top-btn{display: table-cell;width: 180px;vertical-align: middle;}
.contact-wrap .form-con{margin-top:1rem;}
.contact-wrap .form-num{padding:3rem 0 1rem;font-size:1.125rem;font-weight:500;color:#fff}
.contact-wrap .type-wrap{display:flex;}
.contact-wrap .type-wrap li{width:calc(100% / 3 - 1.5rem);padding:0 .5rem;box-sizing:border-box;}
.contact-wrap .type-wrap li a{display:block;;padding:1rem 0;border-radius:.75rem;border:1px solid #666;background:#222;;font-size:1rem;font-weight:500;color:#fff!important;text-align:center;line-height:1.5rem;}
.contact-wrap .type-wrap li a.on{border:1px solid #2138e3;background:#2138e3;color:#fff!important}
.contact-wrap .sel-wrap{display:flex;flex-wrap:wrap;justify-content:space-between;}
.contact-wrap .sel-wrap .sel-inner{width:49%;}
.contact-wrap .sel-wrap .sel-inner.date{position:relative}
.contact-wrap .sel-wrap .sel-inner.date:after{display:block;content:"";position:absolute;top:50%;right:1rem;width:1.25rem;height:1.25rem;background:url(../images/icon/ico-datepicker.png)50%/1.25rem no-repeat;transform:translateY(-50%);}

.contact-wrap .form-con textarea{background:#222;border-radius:.75rem;border-color:#555;font-size:1rem;color:#fff;}
.contact-wrap .form-con .form-line{display:flex;flex-wrap:wrap;justify-content:space-between;margin-top:.5rem;padding: 0.1rem 0;}
.contact-wrap .form-con .form-line.iptBtn .form-item:nth-child(1){width: calc(100% - 90px - .65rem);}
.contact-wrap .form-con .form-line.iptBtn .form-item:nth-child(2){width: 90px;}
.contact-wrap .form-con .form-line.iptBtn .form-item:nth-child(2) .line-btn{position:relative;width: 100%;background:#222;border:1px solid #555}
.contact-wrap .form-con .form-line.iptBtn .form-item:nth-child(2) .line-btn:hover{background:#fff;border:1px solid #fff;}
.contact-wrap .form-con .form-line.iptBtn .form-item:nth-child(2) .line-btn:hover .ico-down{background-position-y: -1.01rem;}
.contact-wrap .form-con .form-line.iptBtn .form-item:nth-child(2) .line-btn .ico-down{position:absolute;top:50%;left:50%;right:inherit;width:1.313rem;height:1.313rem;background:url(../images/icon/ico-down.png)0.08rem 0.15rem/1.125rem no-repeat;transform: translate(-50%, -50%);}
.contact-wrap .form-con .form-line.tmg{margin-top: 1rem;}
.contact-wrap .form-con .form-line.tmg2{margin-top: 1.5rem;}
.contact-wrap .form-con .form-line.first{margin-top: 0;}
.contact-wrap .form-con .form-line .form-item{position:relative;width:49%;}
.contact-wrap .form-con .form-line .form-item input + label {display: none;}
.contact-wrap .form-con .form-line .form-item input:placeholder-shown +  label{position:absolute;top:.75rem;left:1rem;font-size:1rem;font-weight:300;color:#999;cursor:initial;display:block;}
.contact-wrap .form-con .form-line .form-item input:placeholder-shown +  label span{color:#2138e3}

.contact-wrap .form-con .form-line .form-item input{width:100%;height:initial;padding:.75rem 1rem;background:#121212;border-radius:none;border:none;border-bottom:1px solid #555;font-size:1rem;color:#fff;}
.contact-wrap .form-con .form-line .form-item.border input{border-radius:.75rem;border:1px solid #555;background:#222}
.contact-wrap .form-con .form-line.item{justify-content:start;}

.contact-wrap .form-con label{font-size:.875rem;color:#fff}

.sel-wrap .sel-inner:nth-child(3){margin-top:.5rem;}
.custom-select{position: relative;width:100%}
.custom-select select{display:none}
.select-selected{overflow:hidden;height:50px;text-overflow:ellipsis;white-space:nowrap;background-color:#121212;border-bottom:1px solid #555;color:#fff;box-sizing:border-box;line-height:50px;}
.select-selected:after{position:absolute; content: ""; top:23px; right: 10px;width:17px;height:11px;background:url('../images/icon/ico-arrowBottom.png') no-repeat 0 0;transition:all .2s;}
.select-selected.select-arrow-active{border-bottom-left-radius:0px;border-bottom-right-radius:0px}
.select-selected.select-arrow-active:after {transform:rotate(180deg);top:18px;transition:all .2s}
.select-selected.same-as-selected{color:#fff}
.select-items{overflow-y:auto;width:min-content;min-width:100%;max-height:350px;border:1px solid #555;border-top:1px solid #d0d0d0;background:#222;border-bottom-left-radius:7px;border-bottom-right-radius:7px;box-sizing:border-box}
.select-items div{width:min-content;white-space:nowrap;word-break:break-all;color: #999}
.select-items div,.select-selected {font-family: 'Noto Sans KR';font-size:1em;padding:0 2rem 0 1rem; cursor: pointer;}
.select-items div{width:100%;padding:1rem .5rem;}
.select-items {position: absolute;top:calc(100% - 1px);left:0;right:0;z-index:99;font-family: 'Noto Sans KR';font-weight:300}
.select-hide{display: none}
.select-items div:hover, .same-as-selected {color:#fff;background:#666}

input[type=text].datepicker{width:100%;padding:0 1rem;background:#121212;border:none;border-bottom:1px solid #555;font-size:1rem;color:#fff;}
input[type=text].datepicker::placeholder{color:#fff}

.xdsoft_datetimepicker{padding:1rem .5rem;background-color:#222;border:1px solid #555;border-top:1px solid #fff}
.xdsoft_datetimepicker .xdsoft_label{background-color:#222;color:#fff}
.xdsoft_datetimepicker .xdsoft_calendar td, .xdsoft_datetimepicker .xdsoft_calendar th{background-color:#222;border:none;text-align:center;height:30px;font-size:13px;color:#999;padding:2px;box-sizing:border-box;}

.xdsoft_datetimepicker .xdsoft_monthpicker{max-width:80%;margin:0 auto;}
.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_next, 
.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_today_button{display:none!important;}

.xdsoft_datetimepicker .xdsoft_year{float:left;width:52px;height:30px;padding:0;background:#555}
.xdsoft_datetimepicker .xdsoft_month{float:right;position:relative;width:52px;height:30px;margin-right:30px;padding:0;text-align:center;background:#555}
.xdsoft_datetimepicker .xdsoft_year >  span, .xdsoft_datetimepicker .xdsoft_month > span{padding:0 10px;line-height:30px;text-decoration:none!important;}
.xdsoft_datetimepicker .xdsoft_label i{position:absolute;right:-30px;width:30px;height:30px;background:#fff url(../images/icon/date-month-arrow.png)50%/12px no-repeat;opacity:1!important}

.xdsoft_datetimepicker .xdsoft_calendar td:hover > div{background-color:#2138e3;}
.xdsoft_datetimepicker .xdsoft_calendar td > div{background-color:#333;height:30px;line-height:30px}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month{background:#222;color:#222}
.xdsoft_datetimepicker .xdsoft_calendar td>div{padding-right:0}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, 
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current{background:#222;box-shadow:none;color:#fff;font-weight:400}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div.xdsoft_current{background:#2138e3;box-shadow:none;color:#fff;font-weight:400}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default > div, 
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current > div{background:#2138e3;color:#fff}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div.xdsoft_current > div{background:#222;color:#2138e3}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today{color:#fff}

.xdsoft_datetimepicker .xdsoft_datepicker{margin-left:0}
.xdsoft_datetimepicker .xdsoft_datepicker.active+.xdsoft_timepicker{margin-top:20px}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box{height:188px;border:none;}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div{height:31px;border-top:none;background:#333;line-height:31px;font-size:13px}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev,.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next{background:url(../images/icon/date-time-arrow.png)50%/1rem no-repeat}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next{transform:rotate(180deg);}

.xdsoft_datetimepicker .xdsoft_label>.xdsoft_select{background:#222;border:1px solid #555}
.xdsoft_datetimepicker .xdsoft_label>.xdsoft_select.xdsoft_monthselect{left:0;right:-30px}
.xdsoft_datetimepicker .xdsoft_label>.xdsoft_select.xdsoft_yearselect{left:0;right:-30px}
.xdsoft_datetimepicker .xdsoft_label>.xdsoft_select>div>.xdsoft_option{padding:7px 20px 7px 15px;font-size:13px;font-weight:300;}
.xdsoft_datetimepicker .xdsoft_label>.xdsoft_select>div>.xdsoft_option.xdsoft_current{background:#222;color:#fff;font-weight:300;}

.xdsoft_scrollbar{width:3px}

form button {display:block;width:100%;border-radius:.75rem;background:#2138e3;color:#fff;font-weight:500;line-height:50px;text-align:center;}

.checkbox {vertical-align: top;margin: 0;}
.checkbox input[type=checkbox]:checked + i {background-position-y: -18px;}
label {position: relative;font-weight: 500;color: #fff;cursor: pointer;}
.checkbox {overflow: hidden;position: relative;display: inline-block;width: 18px;height: 18px;margin-right: 10px;vertical-align: -4px;cursor: pointer;}
label:after {content: "";position: absolute;right: 100%;bottom: -2px;left: 18px;display: block;height: 1px;background: #2e3092;transition: 0.1s;}
.checkbox input[type=checkbox] {position: relative;width: 18px;height: 18px;margin: 0;padding: 0;cursor: pointer;opacity: 0;}
input[type=checkbox] {width: 18px;height: 18px;vertical-align: top;cursor: pointer;}
.checkbox i {position: absolute;top: 0;left: 0;right: 0;bottom: 0;background: url("../images/icon/ico-chk.png") no-repeat 0 0;}

.txt-link {font-size: .875rem;font-weight: 500;font-style: normal;position: relative;color: #fff;}
.txt-link:after {content: "";position: absolute;left: 0;right: 0;bottom: -1px;display: block;height: 1px;background: #fff;}
label + .txt-link {margin-left: 5px;}

.line-btn{display:block;position:relative;width:11.25rem;height:50px;padding:.75rem 1rem;border-radius:.75rem;border:1px solid #666;;font-size:1rem;font-weight:500;color:#fff;box-sizing:border-box;transition:all .2s;}
.line-btn .ico-arrow{display:inline-block;position:absolute;top:50%;right:1.25rem;width:1.375rem;height:.938rem;background:url(../images/icon/ico-arrow.png)0 -1rem/1.375rem no-repeat;transition:all .2s;transform:translateY(-50%);}

.line-btn:hover{background:#fff;color:#000}
.line-btn:hover  .ico-arrow{background-position-y:0}

/* works */

.works-header{position:fixed;top:3.75rem;right:8rem;z-index:9998;text-align:center;
  -webkit-transition-duration:.5s;
  -webkit-transition-timing-function:ease;
  transition-duration:.5s;
  transition-timing-function:ease;}

.works-header .mobileVer{position:relative;float:right;display:inline-block;transition:padding .5s;-webkit-transition:padding .5s;-ms-transition:padding .5s;}
.works-header .mobileVer a{display:inline-block;width:8rem;padding-left:1.5rem;border-radius:10rem;color:#fff;line-height:3rem;text-align:left;}
.works-header .mobileVer>a{position:relative;border:1px solid #fff;background:url(../images/icon/ico-downArrow.png) 85% 50%/12px no-repeat;}
.works-header .mobileVer ul a.on{background:url(../images/icon/ico-upArrow.png) 85% 50%/12px no-repeat;color:#000}
.works-header .mobileVer ul{display:none;position:absolute;top:0;right:0;border-radius:20px;border:1px solid #fff;background:#fff;opacity:.9;}
.works-header.white{top:1.25rem}
.works-header.white .mobileVer{transition:padding .5s;-webkit-transition:padding .5s;-ms-transition:padding .5s;}
.works-header.white .mobileVer>a{color:#fff;border-color:#fff;background:url(../images/icon/ico-downArrow.png) 85% 50%/12px no-repeat;}
.works-header.white .mobileVer ul{top:.65em}
.works-header .dropDown-list a{color:#000}

.work-section{overflow:hidden;height:100vh;}

.section-left, .section-right{width:50%;float:left;}
.section-inner{position:relative;overflow:hidden;}
.section-inner .infoTxt{position:absolute;left:2.5em;bottom:2.2em;color:#fff;z-index:1}
.section-inner .infoTxt p{font-size:1em;font-weight:300;}
.section-inner .infoTxt strong{font-size:1.25rem;font-weight:500}

.work-section .section-left{height:100%}
.work-section .section-left .section-inner{height:100%}

.work-section .section-right{height:100%;}
.work-section .section-right .section-inner{height:50%;}
.work-section.type1 .section-left {width:100%;height:100%;}

.work-section.type4 .section-right .section-inner:not(:first-child){width:50%;float:left;}
.work-section.type2 .section-right .section-inner{height:100%;}

.work-section img.pc-img{display:block;}
.worksBg{position:absolute;top:-1px;left:-1px;right:-1px;bottom:-1px;transition:1s;background-size:cover;background-position:50%;background-repeat:no-repeat}
.worksBg:hover{transform:scale(1.05);}
.hicare{background:url(../images/works/mando-hiCare-pc.jpg)50% 50% no-repeat;background-size:cover;}
.kyochon{background:url(../images/works/kyochon-pc.jpg)50% 50% no-repeat;background-size:cover;}
.maserati{background:url(../images/works/maserati-event-pc.jpg)50% 50% no-repeat;background-size:cover;}
.handsup{background:url(../images/works/avivgame-star-pc.jpg)50% 50% no-repeat;background-size:cover;}
.sr{background:url(../images/works/sr-pc.jpg)50% 50% no-repeat;background-size:cover;}
.brMall{background:url(../images/works/br-mall-pc.jpg)50% 50% no-repeat;background-size:cover;}
.brSmartWork{background:url(../images/works/br-smartwork-pc.jpg)50% 50% no-repeat;background-size:cover;}
.brSangjo{background:url(../images/works/br-sangjo-pc.jpg)50% 50% no-repeat;background-size:cover;}
.brGroup{background:url(../images/works/br-group-pc.jpg)50% 50% no-repeat;background-size:cover;}
.brCarnegie{background:url(../images/works/br-carnegie-pc.jpg)50% 50% no-repeat;background-size:cover;}
.brFuneral{background:url(../images/works/br-funeral-pc.jpg)50% 50% no-repeat;background-size:cover;}
.cap{background:url(../images/works/cap-pc.jpg)50% 50% no-repeat;background-size:cover;}
.mcmCharm{background:url(../images/works/mcm-charm-pc.jpg)50% 50% no-repeat;background-size:cover;}
.scpc2019{background:url(../images/works/scpc2019-pc.jpg)50% 50% no-repeat;background-size:cover;}
.sstf2018{background:url(../images/works/sstf2018-pc.jpg)50% 50% no-repeat;background-size:cover;}
.scpc2018{background:url(../images/works/scpc2018-pc.jpg)50% 50% no-repeat;background-size:cover;}
.sstf2019{background:url(../images/works/sstf2019-pc.jpg)50% 50% no-repeat;background-size:cover;}
.saic2018{background:url(../images/works/saic2018-pc.jpg)50% 50% no-repeat;background-size:cover;}
.saif2018{background:url(../images/works/saif2018-pc.jpg)50% 50% no-repeat;background-size:cover;}
.scpc2017{background:url(../images/works/scpc2017-pc.jpg)50% 50% no-repeat;background-size:cover;}
.mindBridge{background:url(../images/works/mind-bridge-pc.jpg)50% 50% no-repeat;background-size:cover;}
.avivApp{background:url(../images/works/aviv-app-pc.jpg)50% 50% no-repeat;background-size:cover;}
.codeGround{background:url(../images/works/codeground-pc.jpg)50% 50% no-repeat;background-size:cover;}
.mcm{background:url(../images/works/mcm-pc.jpg)50% 50% no-repeat;background-size:cover;}
.scpcFace{background:url(../images/works/scpc-facebook-pc.jpg)50% 50% no-repeat;background-size:cover;}
.tomntoms{background:url(../images/works/tomntoms-pc.jpg)50% 50% no-repeat;background-size:cover;}
.scpc2016{background:url(../images/works/scpc2016-pc.jpg)50% 50% no-repeat;background-size:cover;}
.swMembership{background:url(../images/works/membership-pc.jpg)50% 50% no-repeat;background-size:cover;}
.pureyou{background:url(../images/works/pureyou-pc.jpg)50% 50% no-repeat;background-size:cover;}
.cat{background:url(../images/works/cat-pc.jpg)50% 50% no-repeat;background-size:cover;}
.basicHouse{background:url(../images/works/basichouse-pc.jpg)50% 50% no-repeat;background-size:cover;}
.sstf2017{background:url(../images/works/sstf2017-pc.jpg)50% 50% no-repeat;background-size:cover;}
.bmw{background:url(../images/works/bmw-pc.jpg)50% 50% no-repeat;background-size:cover;}
.scpc2018-video{background:url(../images/works/scpc2018-video-pc.jpg)50% 50% no-repeat;background-size:cover;}
.sw-video{background:url(../images/works/sw-video-pc.jpg)50% 50% no-repeat;background-size:cover;}
.sstf2017-video{background:url(../images/works/sstf2017-video-pc.jpg)50% 50% no-repeat;background-size:cover;}
.scpc2017-video{background:url(../images/works/scpc2017-video-pc.jpg)50% 50% no-repeat;background-size:cover;}
.scpc2017-video2{background:url(../images/works/scpc2017-video2-pc.jpg)50% 50% no-repeat;background-size:cover;}
.scpc2016-video{background:url(../images/works/scpc2016-video-pc.jpg)50% 50% no-repeat;background-size:cover;}
.sstf2018-video{background:url(../images/works/sstf2018-video-pc.jpg)50% 50% no-repeat;background-size:cover;}


#wrapper #sub-container {
  min-height: calc(100vh - 82px);
}
#wrapper #sub-container .contents-align {
  position: relative;
  padding-top: 105px;
  min-height: 100vh;
  background: #fff;
  box-sizing: border-box;
}
#wrapper #sub-container .contents-align.black {
  display: flex;
  align-items: center;
  background: #000;
}
.gnb-chk.work-detail{background:#000;padding:2rem 4rem}

.con-wrap {
  position: absolute;
  top: 105px;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #fff;
}
.con-wrap .divide-left {
  width: 65.625vw;
  overflow: hidden;
}
.con-wrap .divide-right {
  position: relative;
  width: 34.375vw;
  overflow: hidden;
  border-left: 1px solid #dbdbdb;
  box-sizing: border-box;
}
.con-wrap .divide-right .pj-scroll-area {
  position: absolute;
  top: 0;
  bottom: 60px;
  overflow-y: auto;
}
.con-wrap .divide-right .pj-scroll-area .scroll-pdCon {
  padding: 2.3em 3em;
  box-sizing: border-box;
  overflow-y: auto;
}
.con-wrap .divide-right .pj-scroll-area .mCS_no_scrollbar_y .scroll-pdCon {
  padding: 2.3em 3em 2.3em;
}
.con-wrap .divide-right .pj-scroll-area .pj-info-subs {
  margin-top: 1.3em;
  font-size: 16px;
  font-weight: 400;
  color: #222;
  letter-spacing: -0.3px;
  line-height: 1.95;
  word-break: keep-all;
}
.con-wrap .divide-right .pj-scroll-area .pj-info-subs .pj-img {
  margin: 1em auto;
}

.mCSB_inside > .mCSB_container {
  margin-right: 3em;
}

.pj-info-top {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 1.4em;
}
.pj-info-top h1 {
  font-size: 1.5em;
  font-weight: 600;
  color: #222;
}
.pj-info-top .pj-info-detail {
  overflow: hidden;
  display: table;
  width: 100%;
  margin-top: 0.9em;
}
.pj-info-top .pj-info-detail .detail-cell {
  display: table-cell;
}
.pj-info-top .pj-info-detail .detail-cell span {
  font-size: 14px;
  font-weight: 400;
  color: #222;
}
.pj-info-top .pj-info-detail .detail-cell .cell-label {
  display: inline-block;
  position: relative;
  margin-right: 1em;
  padding-right: 0.5em;
  text-transform: uppercase;
  font-weight: 600;
}
.pj-info-top .pj-info-detail .detail-cell .cell-label:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -1px;
  background: #dbdbdb;
  width: 1px;
  height: 10px;
  transform: translateY(-50%);
}

.pj-info-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: #121212;
  display: flex;
}
.pj-info-bottom a {
  position: relative;
  display: block;
  height: 100%;
  overflow: hidden;
}
.pj-info-bottom a .ico-direction {
  width: 60px;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.pj-info-bottom a .ico-direction.prev {
  float: left;
  background-image: url("../images/icon/ico-arrowL.png");
}
.pj-info-bottom a .ico-direction.next {
  float: right;
  background-image: url("../images/icon/ico-arrowR.png");
}
.pj-info-bottom a .dir-txt {
  display: block;
  float: left;
  width: 65px;
  color: #999;
  font-weight: 600;
  font-size: 1.125em;
  text-transform: uppercase;
  line-height: 60px;
  text-align: center;
}
.pj-info-bottom a .pj-name {
  position: absolute;
  right: 0;
  display: block;
  float: left;
  line-height: 60px;
  max-width: calc(100% - 125px);
}
.pj-info-bottom a .pj-name span {
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0 1em;
  text-overflow: ellipsis;
  font-style: normal;
  color: #fff;
  font-size: 1.125em;
  font-weight: 600;
  white-space: nowrap;
  box-sizing: border-box;
}
.pj-info-bottom .pj-prev-area {
  flex: 1;
  position: relative;
}
.pj-info-bottom .pj-prev-area .pj-name {
  left: 120px;
}
.pj-info-bottom .pj-prev-area .pj-name span {
  padding-right: 0;
}
.pj-info-bottom .pj-center-area {
  flex-shrink: 0;
  position: relative;
  width: 80px;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
}
.pj-info-bottom .pj-next-area {
  flex: 1;
  position: relative;
}
.pj-info-bottom .pj-next-area .pj-name {
  left: 80px;
}
.pj-info-bottom .pj-next-area .pj-name span {
  padding-left: 0;
}


.list-videoCon-wrap {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
}
.list-videoCon-wrap .videoCon {
  position: relative;
  display: none;
  justify-content: center;
  background: #000;
  transition: all 0.2s;
  /*
  video{
  	position:absolute;
  	top: 0;
  	bottom: 0;
  	height:100%;
  }
  */
}
.list-videoCon-wrap .videoCon iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.list-videoCon-wrap .videoCon.on {
  display: inherit;
  flex: 100%;
  transition: all 0.2s;
}
.list-videoCon-wrap .videoCon-item {
  flex-shrink: 0;
  height: 60px;
  cursor: pointer;
  border-bottom: 1px solid #dbdbdb;
}
.list-videoCon-wrap .videoCon-item .videoCon-name {
  padding-left: 2em;
  background: url("../images/icon/ico-arrowOn.png") no-repeat calc(100% - 2em) 50%;
  color: #222;
  font-size: 1.5em;
  font-family: "manrope";
  text-transform: uppercase;
  font-weight: 600;
  line-height: 60px;
}
.list-videoCon-wrap .videoCon-item .videoCon-name.on {
  background-image: url("../images/icon/ico-arrowTop.png");
}

.mCSB_outside + .mCSB_scrollTools{right:0}
.mCSB_inside > .mCSB_container{margin-right:0;}
.mCSB_scrollTools{width:5px}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{width:5px}
.mCSB_scrollTools .mCSB_draggerRail{display:none}
.mCSB_scrollTools .mCSB_draggerContainer{background:none}
@-webkit-keyframes graAni {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }
@-moz-keyframes graAni {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }
@-o-keyframes graAni {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }
@keyframes graAni {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

@-webkit-keyframes txtShow {
  0% {
    top: 5.5em;
    opacity: 0; }
  50% {
    top: 0;
    opacity: 1; }
  100% {
    top: -5.5em;
    opacity: 0; } }
@-moz-keyframes txtShow {
  0% {
    top: 5.5em;
    opacity: 0; }
  50% {
    top: 0;
    opacity: 1; }
  100% {
    top: -5.5em;
    opacity: 0; } }
@-o-keyframes txtShow {
  0% {
    top: 5.5em;
    opacity: 0; }
  50% {
    top: 0;
    opacity: 1; }
  100% {
    top: -5.5em;
    opacity: 0; } }
@keyframes txtShow {
  0% {
    top: 5.5em;
    opacity: 0; }
  50% {
    top: 0;
    opacity: 1; }
  100% {
    top: -5.5em;
    opacity: 0; } }


@keyframes rolling { 
  0% { 
  transform: translateX(100%); 
  -webkit-transform: translateX(100%); 
  } 
  100% { 
  transform: translateX(-100%); 
  -webkit-transform: translateX(-100%); 
  } 
  } 
  @keyframes rolling2 { 
  0% { 
  transform: translateX(0%); 
  -webkit-transform: translateX(0%); 
  } 
  100% { 
  transform: translateX(-200%); 
  -webkit-transform: translateX(-200%); 
  } 
  } 


@keyframes wave-animate{
  0%{
    transform:scale(1);
    opacity:1;
    transform-origin:center;
  }
  100%{
    transform:scale(1.3);
    opacity:0;
    transform-origin:center;}
  }



  @keyframes wave-animate2{
    0%{
      transform:scale(1);
      opacity:1;
      transform-origin:center;
    }
    100%{
      transform:scale(1.2);
      opacity:0.7;
      transform-origin:center;}
    }
  
    @keyframes scroll { 
      0% { 
      transform: translateY(.25rem); 
      -webkit-transform: translateY(.25em); 
      } 
      50% { 
        transform: translateY(0); 
        -webkit-transform: translateY(0); 
      } 
      100% { 
        transform: translateY(.25rem); 
        -webkit-transform: translateY(.25rem); 
      } 
      } 



