@charset "utf-8";

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {margin: 0; padding: 0; border: 0; outline: 0; background: transparent; -webkit-text-size-adjust:none;}
table{border-spacing:0}
body {font:16px/normal 'Noto Sans KR', 'Malgun Gothic', 'Dotum', sans-serif; color:#000; font-weight:300;letter-spacing:-.3px;word-break:keep-all;}
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary {display: block;}
nav, menu, ul, dl, li {list-style: none;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after,
q:before, q:after {content: ''; content: none;}
a:link {text-decoration:none}
small {font-size:0.9em;}
ins {background-color: #ff9; color: #000; text-decoration: none;}
mark {background-color: #ff9; color: #000; font-weight: bold;}
del {text-decoration: line-through;}
abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}
table {width:100%; border:0 none; table-layout:fixed;}
table td, table th {padding:0; border:0 none;}
img {vertical-align:middle; border:0 none; max-width:100%; height:auto;}
hr, legend {visibility:hidden; height:1px; width:1px; position:absolute; line-height:0;}
h1, h2, h3, h4, h5, h6 {font-size:1em; font-weight:400;}
strong {font-weight:400;}
table{border-collapse:collapse}
input, select, textarea, button {font:15px/normal 'Noto Sans KR', 'Malgun Gothic', '맑은 고딕', 'Dotum', '돋움', sans-serif; color:#000; vertical-align:middle; padding:0; margin:0; resize:none; font-weight:300; box-sizing:border-box;}
input[type=text],
input[type=password],
input[type=file],
input[type=email],
input[type=number],
input[type=search] {height:50px;padding:0 .5em;border:1px solid #e5e5e5; -webkit-appearance:none; -moz-appearance:none; -ms-appearance:none; -o-appearance:none; appearance:none;}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {-webkit-appearance:none;margin:0}
textarea {width:100%; padding:1em; border:1px solid #cfd9d9; background:#fff; line-height:1.4; -webkit-appearance:none; -moz-appearance:none; -ms-appearance:none; -o-appearance:none; appearance:none; line-height:normal;}
input[type=text]:focus,input[type=password]:focus,input[type=email]:focus,input[type=number]:focus,textarea:focus {outline: 0 none;}
input[type=checkbox], input[type=radio] {width:20px; height:20px; vertical-align:top; cursor:pointer;}
input[type=submit], input[type=image], input[type=reset], button {cursor:pointer; border:0 none; background:none; border-radius:0; -webkit-border-radius:0; -webkit-appearance:none; -moz-appearance:none; -ms-appearance:none; -o-appearance:none; appearance:none; outline:0 none;}

::-webkit-input-placeholder {color:#a9a9a9;}
:-moz-placeholder {color:#a9a9a9;}
::-moz-placeholder {color:#a9a9a9;}
:-ms-input-placeholder {color:#a9a9a9;}

select {height:45px;padding:0 2em 0 .5em;color:#000;border:1px solid #ebebeb; background:#fff url('../images/icon/ico-select.png') no-repeat 100% 50%; -webkit-border-radius:0; -webkit-appearance:none; -moz-appearance:none; -ms-appearance:none; -o-appearance:none; appearance:none; vertical-align:top; vertical-align:middle;}
select::-ms-expand {display:none}

input, textarea, button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

/* noto-sans-kr-300 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../data/fonts/noto-sans-kr-v13-latin_korean-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../data/fonts/noto-sans-kr-v13-latin_korean-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-300.woff') format('woff'), /* Modern Browsers */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-regular - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../data/fonts/noto-sans-kr-v13-latin_korean-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../data/fonts/noto-sans-kr-v13-latin_korean-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-regular.woff') format('woff'), /* Modern Browsers */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-500 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../data/fonts/noto-sans-kr-v13-latin_korean-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../data/fonts/noto-sans-kr-v13-latin_korean-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-500.woff') format('woff'), /* Modern Browsers */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-900 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('../data/fonts/noto-sans-kr-v13-latin_korean-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../data/fonts/noto-sans-kr-v13-latin_korean-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-900.woff') format('woff'), /* Modern Browsers */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../data/fonts/noto-sans-kr-v13-latin_korean-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../data/fonts/noto-sans-kr-v13-latin_korean-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-700.woff') format('woff'), /* Modern Browsers */
       url('../data/fonts/noto-sans-kr-v13-latin_korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}



@font-face {
  font-family: 'Myriad Pro';
  src: url('../data/fonts/MYRIADPRO-BOLD.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('../data/fonts/MYRIADPRO-SEMIBOLD.woff') format('woff');
  font-weight: 500;
  font-display: auto;
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('../data/fonts/MYRIADPRO-REGULAR.woff') format('woff');
  font-weight: 400;
  font-display: auto;
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('../data/fonts/MyriadPro-Light.woff') format('woff');
  font-weight: 300;
  font-display: auto;
}
@font-face {
  font-family: 'manrope';
  src: url('../data/fonts/manrope-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'manrope';
  src: url('../data/fonts/manrope-regular.woff') format('woff');
  font-weight: 400;
  font-display: auto;
}
