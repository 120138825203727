@charset "utf-8";

@media screen and (max-width:1520px){
	
.intro-tit .tit-line-outer{margin-left:0}
.intro-tit{font-size:13px;}
.intro-tit .tit-line{width:1180px}

.tit-sub{font-size:2.5em;padding-top:100px}

.aviv-area .process .circle-line{width:13.25rem;height:13.25rem;}
.aviv-area .process .circle-line > div{padding-top:2rem;}
.aviv-area .process .small{padding-top:.5rem}

.scroll-pdCon{font-size:13px}
.con-wrap .divide-right .pj-scroll-area .pj-info-subs{font-size:15px}
.con-wrap .divide-right .pj-scroll-area .scroll-pdCon{padding:2em 3em 2em 2em;}
.con-wrap .divide-right .pj-scroll-area .mCS_no_scrollbar_y .scroll-pdCon{padding:2em 2em 2em;}
.pj-info-bottom a .pj-name{display:none}
.pj-info-bottom a .dir-txt{width:calc(100% - 60px);}
.list-videoCon-wrap .videoCon-item .videoCon-name{font-size:1.3em}
.list-videoCon-wrap .videoCon-item .videoCon-name{background-position-x:calc(100% - 3em);}
.pj-info-top .pj-info-detail .detail-cell span{font-size:13px}
}/*1520*/

@media screen and (max-width:1200px){
body,html{font-size:15px}
.gnb-chk{padding:3rem 1.5rem}
.gnb-chk.main{position:fixed;display:flex;justify-content:space-between;padding:3rem 1.5rem;background:#000}
.gnb-chk.main.color-white{padding:3rem 1.5rem}
.gnb-chk.main .top-logo{position:initial}
.gnb-chk.main .btn-menu{position:initial}
.gnb-chk.on{padding:3rem 1.5rem}
.gnb-chk.color-white{padding:3rem 1.5rem}
.gnb-chk.work-detail{padding:2rem 1.5rem}
.gnb-chk.work-detail.on{padding:2rem 1.5rem}
.works-header.white{top:2.75rem;right:5rem;}
.works-header{top:2.75rem;right:5rem;}
.con-width{padding:0 1.5rem;}
.sub-top h2{padding-left:1.5rem;}
.sec6 .scroll .works-con{padding:0 1.5rem}

.intro-tit{font-size:11px}
.intro-tit .tit-line{width:initial}

.aviv-area .sec2-inner{padding:0 1.5rem;}
.aviv-area .swiper-wrapper .swiper-slide{height:initial;}
.aviv-area .circle-line{width:20rem;height:20rem;}
.aviv-area .sub-txt{padding-bottom:6rem;}

.aviv-area .sec2-con{display:block;}
.aviv-area .process{justify-content:center;}
.aviv-area .process.process2{margin-top:1rem;}
.aviv-area .process .circle-line > div{padding-top:2rem}

.work-section{overflow:hidden;height:70vh;}
.section-inner .infoTxt{bottom:2rem;left:2rem}

.pj-info-top .pj-info-detail .detail-cell{display:block;}
#wrapper #sub-container .contents-align{padding-top:98px}
.con-wrap{top:98px}
.play-area-wrapper .btn-sound{left:2rem;bottom:2rem;}
}/*1200*/

@media screen and (max-width:1024px){

body,html{font-size:14px}

.works-header{top:2.75rem;}
.menu-con .contact-con{margin-top:4em}

.background-video{position:initial;}
.background-video video{position:initial;transform:none;}

.contact-con .contact-group .con-itemWrap{display:block;width:100%;max-width:280px;margin:0 auto;text-align:left;}
.contact-con .contact-group .con-itemWrap:first-child{margin-bottom:2em;border-right:none}
.contact-con .con-itemWrap:first-child span{padding-right:2.6em}

.about-box-list .box .box-inner{min-height:19rem;}

.studio-img-area .round-box{border-radius:1rem;}

.footer-inner .fR{float:none}
.footer-inner .fL{float:none;margin-top:.8rem}
.footer-inner .fL .company-info{display:block;padding:1rem 0}
.fR .company-info{display:none}
.footer-inner .company-info li:nth-child(2):before,.footer-inner .company-info li:nth-child(2):after{display:none;}
.footer-inner .fR .txt-link{margin-left:0}
.footer-inner .company-info li{float:none;padding:0;}


.fullpage-wrapper .swiper-container{height:initial}
.fullpage-wrapper .swiper-wrapper{display:block;}
.fullpage-wrapper .swiper-slide{width:initial;height:initial;}

.visual-area .section1-left{height:100vh;width:100vw;padding:0 1.5rem}
.visual-area .section1-right{}
.visual-area .section1-right video{width:100%}

.scrl-con{top:initial;right:initial;bottom:1.5rem;transform:none;}

.aviv-area .swiper-wrapper{height:auto;}
.aviv-area .circle-line{width:18rem;height:18rem;}
.aviv-area .circle-line p:nth-child(1){padding-top:1rem;font-size:2rem;}
.sec3 video,.sec4 video, .sec5 video{width:100%}

.work-area .swiper-wrapper{height:auto;padding:5rem 0 0;background:#000}
.work-area .bg-black .title{font-size:3rem;}
.work-area .swiper-slide{position:initial;padding:1.5rem 1.5rem 0 ;}

.work-area .bg-black{position:initial;}
.work-area .bg-black p{font-size:1.25rem;}
.sec5 .work-area .swiper-wrapper{padding:5rem 0 5rem}
.work-area .bg-video{width:100%;transform:none;}
.work-area .bg-video video{position:initial;transform:none;}
.fp-section, .fp-tableCell{height: auto !important;}

.sec6 .works-con .list-r .r-bottom li:last-child{display:none;}
.work-area.swiper-container{height:initial;}

.sec6 .tit-sub{padding-top:0}
.fp-viewing-5 .contact-btn{border:1px solid #fff}

.aviv-area .process .circle-line > div{padding-top:1rem}

#wrapper #sub-container .contents-align{padding-top:91px}
.con-wrap{display:inherit;position:relative;top:0;}
.con-wrap .divide-right{border-left:none;}
.con-wrap .divide-left,.con-wrap .divide-right{width:100%}
.con-wrap .divide-right .pj-scroll-area{position:relative;height:100%;overflow-y:hidden;}
#wrapper #sub-contents .contents-align{padding-top:0;height:100%;}
.list-videoCon-wrap .videoCon-item .videoCon-name{padding-left:1em;background-position-x:calc(100% - 2em);font-size:1.25em}

.divide-right .mCSB_container{overflow-y:hidden !important}
.pj-info-bottom{position:relative;}
.list-videoCon-wrap .videoCon.on{padding-top:55.5%}

.mCSB_scrollTools.mCSB_1_scrollbar{display:none !important;}
.pj-info-top .pj-info-detail .detail-cell{display:table-cell;}
.play-area-wrapper .btn-sound{left:1rem;bottom:1rem}
.bottom-area a{font-size:2.5rem;}
.bottom-area .bg-inner{min-height:14rem;}
}/*1024*/

@media screen and (max-width:850px){
	
	.intro-tit .tit-line{flex-wrap:wrap;width:initial}
	.intro-tit .tit-line-outer .tit-line .img-txt-concept{display:none}
	.intro-tit .tit-line:last-child .tit:last-child{margin-left:0}

	.list-videoCon-wrap .videoCon-item .videoCon-name{background-position-x: calc(100% - 1.5em);}
}

@media screen and (max-width:768px){
	body,html{font-size:13px}

	.gnb-chk{padding:2rem 1.5rem}
	.gnb-chk.main .top-logo{top:2rem;left:1.5rem;}
	.gnb-chk.main .btn-menu{top:2rem;right:1.5rem;}
	.gnb-chk.main.color-white{padding:2rem 1.5rem}
	.gnb-chk.on{padding:2rem 1.5rem}
	.gnb-chk.color-white{padding:2rem 1.5rem}
	.works-header{top:1.75rem;}
	.works-header.white{top:1.75rem}
	.sub-top{height:28.25rem}
	.sub-con{padding-top:6rem}
	.about-con:nth-child(2){padding-top:6rem}
	.sub-con h3{font-size:1.75rem}
	.about-box-list{display:block;}
	.about-box-list .box{width:100%;}
	.about-box-list .box .box-inner{min-height:initial;text-align:center;}
	.about-box-list .box-bt p{margin:0 auto}
	.about-bt{margin-top:7rem;padding:5rem 0}
	.about-bt .bt-con{display:block;}
	.about-bt .bt-con .bt-con-inner{margin:1rem 0 1.5rem}
	.about-bt .bt-con .bt-con-inner:after,.about-bt .bt-con .bt-con-inner:before{display:none!important;}
	.about-bt .cir-txt{width:13rem;height:13rem;}
	.about-bt .cir-txt:before{width:11rem;height:11rem;}

	.studio-slide .swiper-slide{padding:0 .5rem;}
	.studio-img-area .img-list.col4 li{width:50%;padding:.5rem;}
	.studio-img-area .img-list.col4 li:nth-child(odd){padding-left:0;}
	.studio-img-area .img-list.col4 li:nth-child(even){padding-right:0;}
	.studio-img-area .img-list.col4 .round-box img{width:10rem}
		
	/* .contents-align{padding:0 1.5rem} */
	.contact-wrap .form-top{display:block;}
	.contact-wrap .form-top .form-top-tit{display:block;}
	.contact-wrap .form-top .form-top-btn{display:block;margin-top:2rem;}
	.contact-wrap .form-top .form-top-btn .line-btn{height:50px;padding:0 1rem;line-height:46px;}

	.contact-wrap .type-wrap li{width:calc(100% / 3);padding:0.25rem;}
	.contact-wrap .form-con .form-line{display:block;margin-top:0}
	.contact-wrap .form-con .form-line.iptBtn{display:flex}
	.contact-wrap .form-con .form-line .form-item{width:100%;margin-top:.5rem}
	.contact-wrap .form-con .form-line .form-item label{top:.95rem;font-size:1.125rem;}
	.contact-wrap .form-con .form-line .form-item input{padding:1rem;font-size:1.125rem;}
	input[type=text].datepicker{font-size:1.125rem;}
	.select-items div, .select-selected{font-size:1.125rem;}
	.contact-wrap .form-con textarea{font-size:1.125rem;}
	.contact-wrap .form-con .form-line.iptBtn .form-item:nth-child(1){width:calc(100% - 70px - .65rem)}
	.contact-wrap .form-con .form-line.iptBtn .form-item:nth-child(2){width:70px;}
	.line-btn{height:46px}
	.select-items div{padding:1rem}
	.contact-wrap .sel-wrap{display:block;}
	.contact-wrap .sel-wrap .sel-inner{width:100%;margin-top:.5rem}
	.contact-wrap .sel-wrap .sel-inner.date:after{width:1.5rem;height:1.5rem;background-size:1.5rem}

	.footer-inner .fL,
	.footer-inner .fR{font-size:13px}

	.aviv-area .sec2-con .circle-wrap:nth-child(3){margin-top:-4rem}
	.aviv-area .sub-txt{font-size:1.25rem}
	.aviv-area .sub-txt .m-block{display:block;}
	
	.aviv-area .swiper-wrapper .swiper-slide.bg2{background-position:40% 50%;background-size:cover;}
	.aviv-area .process{flex-direction: column;}
	.aviv-area .process.process2{margin:-.75rem}
	.aviv-area .process .circle-line{margin:-.75rem}
	.aviv-area .process .circle-line{width:15.25rem;height:15.25rem}
	.aviv-area .process .circle-line > div{padding-top:2rem;}


	.sec6 .works-con ul{display:flex;flex-wrap:wrap;justify-content:space-between;}
	.sec6 .works-con li{display:block;width:49%}
	.sec6 .works-con .list-l{padding:0}
	.sec6 .works-con .list-r .r-bottom{display:none;}
	.sec6 .works-con .list-r .r-top .work-txt{padding-top:.25rem}
	.sec6 .works-tab{padding:3rem 0 2rem}
	.sec6 .more-btn{padding:.75rem 2rem;font-size:1.25rem;}
	.work-section{height:100vh}
	.section-left, .section-right{width:100%;float:none;}
	.work-section .section-left,
	.work-section .section-right{height:50%;}
	.section-inner .infoTxt{bottom:1.5rem;left:1rem}

	#wrapper #sub-container .contents-align{padding-top:85px}
}/*768*/

@media screen and (max-width:590px){
	.intro-tit{font-size:7px}
	.con-wrap .divide-right .pj-scroll-area .mCS_no_scrollbar_y .scroll-pdCon{padding: 1.5em 1.5em 1.5em}
	.con-wrap .divide-right .pj-scroll-area .scroll-pdCon{padding: 1.5em 0 1.5em 1.5em}
	.mCSB_inside > .mCSB_container{margin-right:1.5em}
}

@media screen and (max-width:580px){
body,html{font-size:12px}

.footer-inner .fL,.footer-inner .fR{font-size:12px;letter-spacing:-.5px}

#wrapper #sub-container .contents-align{padding-top:78px}
.sec6 .works-con ul{display:block;}
.sec6 .works-con ul li{width:100%;padding:.5rem 0 1rem 0}
.sec6 .works-con .round-img img{width:100%}
.con-wrap .divide-right .pj-scroll-area .mCS_no_scrollbar_y .scroll-pdCon{padding: 1.5em 2em 1.5em 1.5em}
.con-wrap .divide-right .pj-scroll-area .scroll-pdCon{padding: 1.5em 2em 1.5em 1.5em}


}/*570*/


@media screen and (max-width:460px){
	/* body,html{font-size:11px} */
	.con-width{padding:0 1.25rem;}
.sub-top h2{padding-left:1.25rem;}
.sub-con h3{font-size:1.5rem}
.footer-inner .fL{font-size:11px;}
.sub-txt .pc-block{display:inline;}
.aviv-area .circle-line{width:14rem;height:14rem;}

#wrapper #sub-container{min-height: calc(100vh - 131px)}
.list-videoCon-wrap .videoCon-item{height:60px}
.list-videoCon-wrap .videoCon-item .videoCon-name{line-height:60px;}
.con-wrap .divide-right .pj-scroll-area .pj-info-subs{font-size:14px;}
.con-wrap .divide-right .pj-scroll-area .mCS_no_scrollbar_y .scroll-pdCon{padding: 1em 1em 1em}
.pj-info-top .pj-info-detail .detail-cell span{font-size:12px}

}/*450*/

@media screen and (max-width:370px){
	.tit{font-size:3rem;}


}/*450*/